// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import * as XLSX from 'xlsx';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

export type resultDataType = {
    id?: string | number;
    category?: string;
    description?: string;
    amount?: string;
}

export const configJSON = require("./config.js");

export type Props = {
    classes?: Record<string, string>
    workspaceId: number
    currentUserId: string
    state?: any
    handleSavePDF?: any
    resetRegenerate?: () => void
}

interface AiResponse {
    status: string;
    result: Record<string, string>[]
}

interface S {
    resultList?: resultDataType[];
    tableList: {
        status: string;
        result: Record<string, string>[]
    }
}

interface SS {
    id: string | number;
}

class ResultTabController extends BlockComponent<Props, S, SS> {
    getTableDataID: string = "";
    getListResultId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            resultList: [],
            tableList: {
                status: "",
                result: []
            }
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    getTableDataAccordingToSelectedOption = async () => {
        this.setState({ tableList: { status: "", result: [] }})
        try {
            const headers = {
                access_token: `egai-pro-S787gja21jsdibnvopwkqepDJHJdad8Hio0HuIpl341ng671`,
            }
            const promises = this.props.state.selectedOptions.map(async (option: any) => {
            const formData = new FormData();
            formData.append('file', this.props.state.currentFile);

            const response = await fetch(
                `https://demo-buildai.emergegen.ai/extract_deal_terms/${option?.name}`,
                {
                    method: 'POST',
                    headers,
                    body: formData,
                }
            );
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

                    return response.json();
                });
        
                const results: AiResponse[] = await Promise.all(promises);

                // Store each result separately in an array
                this.setState({ tableList: { status: "", result: results as any }})
        } catch (error) {
            
            this.setState({ tableList: { status: "", result: [] }})
        }
    };

    handleGetTableName = (result?: Record<string, string> | Record<string, string>[]) => {
        if (result) {
            if (Array.isArray(result)) {
                return Object.keys(result?.[0])
            } else {
                return Object.keys(result)
            }
        } else return [""]
    }
    traverseAndFlatten = (currentNode: any, target: any, flattenedKey: any) => {
        for (let key in currentNode) {
            if (currentNode.hasOwnProperty(key)) {
                let newKey;
                if (flattenedKey === undefined) {
                    newKey = key;
                } else {
                    newKey = flattenedKey + '.' + key;
                }
    
            const value = currentNode[key];
            if (typeof value === "object") {
                this.traverseAndFlatten(value, target, newKey);
            } else {
                target[newKey] = value;
            }
        }
    }
    }
    
    flatten = (obj: any) => {
        const flattenedObject: any = {};
        this.traverseAndFlatten(obj, flattenedObject, '');
        return flattenedObject;

    }
    getTableRowData = (result?: Record<string, string> | Record<string, string>[]) => {
        if (result) {
         
            if (Array.isArray(result)) {
                return result
            } else {
                return [result]
            }
        } else return []
    }

    async componentDidMount(): Promise<void> {
        this.getTableDataAccordingToSelectedOption()
    }

    // istanbul ignore next
    handleData = () => {
        if (this.props.state.fileFormat === "XLSX") {
            const worksheet = XLSX.utils.json_to_sheet(this.getTableRowData(this.state.tableList.result));
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "AI-review.xlsx");
            const data = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
            this.props.handleSavePDF(new Blob([data]));
        } else {
            const element = document.querySelector("#myTable") as HTMLElement;
            if (element) {
                html2canvas(element).then((canvas: any) => {
                    const imgData = canvas.toDataURL("image/png");
                    const pdf = new jsPDF();
                    const width = 190;
                    const height = (canvas.height * width) / canvas.width
                    pdf.addImage(imgData, 'PNG', 10, 10, width, height);
                    const pdfBlob = pdf.output('blob');
                    this.props.handleSavePDF(pdfBlob);
                });
            }
        }
    }

    // istanbul ignore next
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
        if (prevProps.state?.fileFormat !== this.props.state.fileFormat) {
            this.handleData()
        }
        if (prevProps.state?.isRegenerate !== this.props.state.isRegenerate) {
            this.getTableDataAccordingToSelectedOption()
        }
    }
}

export default ResultTabController;
// Customizable Area End
